import '../../App.css';
import hol from './images/hol.PNG';
export default function HouseofLeaves() {
    return (
        <thing>
            <h1>House of Leaves</h1>
            <hr />
            <p>May 2023 | Theatrical Adaptation | <a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1BWzKwO79-KJp6SP4-XTxXpI5xQT-jHVNtavOR_NYyBk/edit?usp=sharing">Read here</a> </p>
            <p></p>
            <br />
            <img src={hol} width='100%' alt="a copy of house of leaves (a 700 page novel), a highlighter, sticky notes, a laptop, and an open can
            of lychee soda on a table." />
            <br />
            <br />
            <p>An adaptated Mark Z. Danielewski's <i>House of Leaves</i> for the stage. (Not all of it.)  
                I imagined the behemoth of a novel as a three act play and wrote the script for Act I. Written for Theater 126.
            </p>
            <hr />
            <p>If you've read the book, check it out! And if you haven't... I can't say "read it first" in good conscience - it consumed so 
                much of my life in 2023 - but I clearly love it, so your move, buddy...
            </p>
        </thing>
        
    )

}