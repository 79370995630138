import '../App.css';
import shm from '../images/shm.png';
export default function BiD() {
    return (
        <thing>
            <h1>Berkeley Institute of Design</h1>
            <hr />

            <p>Advised by&nbsp;
                <a target="_blank" rel="noreferrer" href="https://shmuh.co/">Shm Garanganao Almeda</a> 
                &nbsp;and <a target="_blank" rel="noreferrer" href="https://people.eecs.berkeley.edu/~bjoern/">Dr. Björn Hartmann</a></p>
            <p>Undergraduate Research Assistant | Sep 2022 - May 2023  </p>
            <br />
            <img src={shm} width='100%' alt="screenshot of a Slack message from Ace Chen that says 'shm and i talked about 
                vtubers u all missed out' with 19 different sad emoji reactions"/>
            <br />
            <br />
            
            <p>I assisted Shm Almeda on research about how new technologies like NFTs and social media platforms change artists' creative 
                practices and online community spaces.</p>
            <p>What does it look and feel like to be an artist in a world where you are constantly asked to learn, make, judge, 
                and compete with new tools and technologies?
            </p>
            <hr />
            <p>Or: the internet is such a cool place to be.</p>

        </thing>
        
    )

}