import '../../App.css';
import hands from './images/hands.jpg';
export default function Hands() {
    return (
        <thing>
            <h1>where to put my hands</h1>
            <hr />
            <p>Jul 2022 - ??? (Sep 2022) | <a target="_blank" rel="noreferrer" href="https://open.spotify.com/show/16CvUq83AC46btCVXtcd2n?si=625dee2ca8b54a31&nd=1&dlsi=c89d7fb7192d4fe1">Listen on Spotify</a> </p>
            <br />
            <img src={hands} width='100%' alt="a scanned piece of paper that reads: WHERE TO PUT MY HANDS, an audio diary + podcast by ace s chen" />
            <br />
            <br />
            <p>I started a weekly podcast to track my voice change after testosterone by talking to friends about whatever 
                we felt like. I wanted to use a long(ish)form medium to listen for changes because I figured I'd speak more
                naturally that way.</p>
            <p>Unfortunately, I grossly underestimated how much work it would be, so I only successfully recorded, edited, and released 
                5 episodes. My voice has since changed quite a bit, but maybe I'll go back to it one day!
            </p>
            <hr />
            <p>These are the episodes we recorded:</p>
            <ul>
                <li>ep 1: voice</li>
                <li>ep 2: ice cream [ft. juliana, my sister] </li>
                <li>ep 3: middle school [ft. kii] </li>
                <li>ep 4: taco bell [ft. sid, my brother] </li>
                <li>ep 5: dreams [ft. jason j] </li>
            </ul>
        </thing>
        
    )

}