import '../../App.css';
import p1 from './images/pfiwh/pfiwh1.jpg';
import p2 from './images/pfiwh/pfiwh2.jpg';
import p6 from './images/pfiwh/pfiwh6.jpg';
import p7 from './images/pfiwh/pfiwh7.jpg';
export default function pfiwh() {
    return (
        <thing>
            <h1>please forget i was her</h1>
            <hr />
            <p>Nov 2018 - Jun 2019 (or present) | Art Installation</p>
            <p>Photos by Nicholas Li</p>
            <p>please forget i was her is an installation exploring and visualizing my life being transmasc. </p>
            <p>Inspired by the Japanese tradition <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/One_thousand_origami_cranes">senbazuru</a>, 
                each of the cranes is a piece of commentary on gender dysphoria and my experience being trans. 
                The project will one day culminate in 999 cranes, rendering the titular wish unfulfilled.</p>
            <br />
            <img src={p1} width='100%' alt="ace, a Chinese trans boy, stands in front of an art installation where strings of origami
                cranes of different materials hang inside a makeshift room framed by sheer black curtains." />
            <p>This work was installed for one week in June 2019 with 590 completed cranes. It was also my "public" coming out. </p>
            <br />
            <img src={p2} width='100%' alt="the upper half of the installation's entrance. cranes hung by fishing line 
            and contained within a 'room' made of PVC pipes and fabric." />
            <p>Upper half of entrance. Cranes hung by fishing line and contained within a 'room' made of PVC pipes and fabric. </p>
            <br />
             <img src={p6} width='100%' alt="detail shot of a green crane near two strands of cranes covered in indecipherable text." />
             <p>Three strands held cranes with the names of trans people who died in between January 2018 and installation date due to fatal violence.</p>
             <br />
             <img src={p7} width='100%' alt="detail shot of a large paper crane, about two feet from tip to tail, suspended in the air." />
             <p>A central crane is folded from a charcoal drawing on newsprint.</p>
            
            <hr />
            
            <p>I used a variety of materials, including traditional origami paper, clear laminate, envelopes, sanitary pad wrappers, newspaper clippings, 
                report cards, and many more. Every crane represents an aspect of my transn existence through illustration, captions, repeated
                text, treatment of material, or the material itself.</p>
            <p>In creating this project, I wanted to visually and physically express how dysphoria feels, particularly to those who have never felt it. 
                But really, it is a piece of art that is viscerally about me, and I wish you could have been there to see it.</p>
            
            <hr />
            <p>I started this artwork in high school and still make cranes for it on and off. I've grown a lot since then, thankfully, but I still
                hold this very close to my heart. Thanks for reading about it! </p>

        </thing>
        
    )

}