import '../App.css';
import y from '../images/yodeai.png';
export default function Yodeai() {
    return (
        <thing>
            <h1>Yodeai</h1>
            <hr />

            <p>Advised by&nbsp;
                <a target="_blank" rel="noreferrer" href="https://niloufar.org/">Dr. Niloufar Salehi</a> 
                &nbsp;and <a target="_blank" rel="noreferrer" href="http://afshin-nikzad.com/">Dr. Afshin Nikzad</a></p>
            <p>UX Researcher + Designer | Jun 2023 - Apr 2024  </p>
            <br />
            <img src={y} width='100%' alt="illustration of a MacOS file browser with a maze of folders related to UC Berkeley classes, with 
            file names like REVISEDfinalFINALVER.pdf." />
            <br />
            <br />
            
            <p>At the UC Berkeley School of Information, I worked on UX research and UI design for Yodeai,
                 an LLM-supported knowledge organization and synthesis tool. </p>
            <p>How might we create more productive and meaningful experiences for knowledge workers through better 
                information visualization and synthesis? </p>
            <hr />
            <p>Or: if your file browsers feels like ^this, you might want to try Yodeai :~) </p>

        </thing>
        
    )

}