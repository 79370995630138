import '../../App.css';
import indent from './images/indent.png';
export default function Indent() {
    return (
        <thing>
            <h1>How We Learn + How We Teach</h1>
            <hr />
            <p>Jul 2021 | <a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/1003081454071489865">Slides here</a> </p>
            <br />
            <img src={indent} width='100%' alt="illustration of red, green, and blue smiley faces wearing party hats" />
            <br />
            <br />
            
            <p>I'm very grateful to have been invited by UW/UX to speak at Indent 2021 about how learning and teaching are variations on the same cycle, and how we can use one to better the other.</p>
            <hr />
            <p>I put together <a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/1003081997775503690">a short resource guide</a> for design educators and presented the cutest slidedeck I've ever made.</p>
        </thing>
        
    )

}