import '../../App.css';
export default function Config() {
    return (
        <thing>
            <h1>Unexpected Places</h1>
            <hr />
            <p>May 2021 | Config, San Francisco, CA</p>
            <br />
            <iframe width="100%" height="50%" src="https://www.youtube.com/embed/uf1Enmc6sG8?si=lbfrS1x98cvUpH2c" title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
            <br />
            <p>Creating something from a blank file is magic. I spoke at Config 2021 about 5 stories of 5 different projects to share how 
                I've used Figma in unexpected ways (and how you can too)!</p>
            <hr />
            <p>You can find the files behind each story here:</p>
            <ul>
                <li><a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/834210307944210537">Animal Crossing Character Maker </a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/966240411741623475">Collaborative work with IDOT</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/966236638302608558">Welcome on the Glade with Blueprint</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/899042758064025062">The Mystery of Everson Manor</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.notion.so/Introduction-to-Figma-Decal-1af70b8891634923bed87bdda4d64640">Figma DeCal</a></li>
            </ul>
        </thing>
        
    )

}