import '../../App.css';
import hs from './images/hs.png';
export default function Homestuck() {
    return (
        <thing>
            <h1>Homestuck Fandom</h1>
            <hr />
            <p>May 2023 | Presentation | <a target="_blank" rel="noreferrer" href="https://www.figma.com/proto/cWlF7k7daonuI7lWL9k5c0/homestuck-preso?page-id=26%3A454&type=design&node-id=20-95&t=LAZ1g9rjiCYC9x8z-0&scaling=scale-down&hotspot-hints=0&hide-ui=1">Slides here</a> </p>
            <p></p>
            <br />
            <img src={hs} width='100%' alt="talking about Homestuck in 2023: a case study of transmedia storytelling and a fandom that refused to die" />
            <br />
            <br />
            <p>Just a presentation about Homestuck. Made for New Media 290: Fandom and Piracy. I accidentally talked for 40 minutes.  
            </p>
            <hr />
            <p>This should explain a lot about me.</p>
        </thing>
        
    )

}