import '../App.css';
import hc from '../images/hc.png';
export default function HealthyCorners() {
    return (
        <thing>
            <h1>Healthy Corners Rewards</h1>
            <hr />

            <p>Product Designer | Jan 2020 - May 2020 | <a target="_blank" rel="noreferrer" href="https://calblueprint.org/">Cal Blueprint</a> </p>
            <p>Collaborators: Project lead, 4 developers, 2 points of contact</p>
            <p>Skills: User research, interaction design, visual design, illustration</p>
            <p> View the case study presentation <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1vYHfniudbJJFvQDSv5Xzc_toMwW08Baa/view?usp=sharing">here</a>.</p>
            <p> View the designs <a target="_blank" rel="noreferrer" href="https://www.figma.com/file/xrIJ4AvkeGBZ29dAO2ve8k/Healthy-Corners-Rewards?type=design&node-id=8198-55062&mode=design&t=FSVUVttVjsJTdG02-11">here</a>.</p>
            <br />
            <img src={hc} width='100%' alt="banner of D.C. Central Kitchen logo and Healthy Corners with illustrations of green and orange produce." />
            <br />
            <br />

            <p><a target="_blank" rel="noreferrer" href="https://dccentralkitchen.org/">DC Central Kitchen</a> (DCCK) is a Washington, 
               D.C.-based nonprofit expanding healthy food access in low-income food deserts. Their Healthy Corners program brings fresh produce to 
               local corner stores at affordable price points. </p>
            <p>However, their existing processes were highly manual and missing ways to communicate 
               with new and existing customers.</p>
            <p>To foster customer discovery and retention, we developed two apps: </p>
            <ul>
                <li>Healthy Corners Rewards, a mobile store locator and customer loyalty app.</li>
                <li>Healthy Corners Clerk, a companion tablet point-of-sale system for store clerks.</li>
            </ul>
            <hr />
            
            <p>Annie Wang was the designer for this project in Fall 2019, then became project lead. I joined the project in Spring 2020,  
               focusing on user research and testing, high fidelity visual design, illustration and brand identity, and facilitating the
               handoff process. </p>

            <br />


        </thing>
        
    )

}