import '../../App.css';
import itw from './images/itw.jpg';
export default function IntoTheWoods() {
    return (
        <thing>
            <h1>Into the Woods</h1>
            <hr />
            <p>Apr 2024 | Second Violinist  </p>
            <br />
            <img src={itw} width='100%' alt="the cast (in costume) and crew (in black) of Into the Woods standing onstage together." />
            <br />
            <br />
            
            <p>In my last semester of college, I got to join the pit orchestra for Barestage Production's <i>Into the Woods</i>! 
                A whirlwind show! Full of twists! And great music! (That was definitely super easy to count!) Played with very talented musicians! </p>
            <hr />
            <p>Thanks again to this lovely group of people for giving me a chance to bust out my violin again before finishing undergrad. 
            </p>
        </thing>
        
    )

}