import '../../App.css';
import ev from './images/everson/everson.png';
import ev1 from './images/everson/ev1.png';
import ev2 from './images/everson/ev2.png';
import ev3 from './images/everson/ev3.PNG';
import ev4 from './images/everson/ev4.png';
import ev5 from './images/everson/ev5.png';
import ev6 from './images/everson/ev6.png';
export default function EversonManor() {
    return (
        <thing>
            <h1>The Mystery of Everson Manor</h1>
            <hr />
            <p>Oct 2020 | Escape Room Game | <a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/899042758064025062/escape-room-the-mystery-of-everson-manor">Play it on Figma here</a> </p>
            <br />
            <img src={ev} width='100%' alt="illustration of several rooms in a house, reading 'The Mystery of Everson Manor, an escape room adventure" />
            <br />
            <br />
            
            <p>The Mystery of Everson Manor is an interactive online escape room that I wrote, illustrated, and built. (Below is a short review
                of my process, which includes spoilers for some puzzles - scroll at your own risk!)
            </p>
            <hr />
            <p>In the first year of the COVID-19 pandemic, I was given a perplexing task: plan a weekend of online activities in place of our 
                semesterly in-person club retreat. After playing point-and-click games all my life, and having visited a handful of 
                in-person escape rooms, I thought that virtually trapping people in a locked room with a timer over their heads would be a 
                great way to bond. </p>
            <br /> <img src={ev1} width='100%' alt="illustration of" /><br /><br />
            
            <p>I chose to use Figma as the starting point because it provided a multiplyer space for people to virtually collaborate. The core 
                puzzle mechanic uses Figma components: players can select an asset and swap between alternate versions to reveal additional 
                information, which mimics examining something suspicious in a real escape room. (This project predates Figma's Variants feature.)
                </p>
            <br /> <img src={ev2} width='100%' alt="food assets. illustrations of different foods, like crab, ginger, and quail eggs. each food item has an alternate
            version that is semi-transparent with text written on it." /><br /><br />
            
            <p> Solving a puzzle, usually by combining hints from different rooms, resolves to a Bit.ly URL. The shortlinks point to Notion pages with 
                a new set of clues. For example, solving a puzle with hints from the father's bedroom, kitchen, and foyer leads to this page with
                a code cipher: </p>

            <br /> <img src={ev3} width='100%' alt="Back in the foyer... you spot something! After turning the photos around, this note drops out of the fireplace. 
            This doesn't look like Mr. Everson's handwriting... a series of symbols and associated letters follows." /><br /><br />

            <p> ... which is used to decode the message that appears when you turn on the fireplace.</p>

            <br /> <img src={ev4} width='100%' alt="illustration of a foyer with a fireplace, couch, chandelier, and photos. the photos are turned at different angles
            and have letters in them. the fireplace has odd symbols lining the pillars." /><br /><br />

            <p> I designed the puzzles to be hard to brute force and nonlinear, encouraging teams of players to either divide and conquer or focus 
                seeking out clues for one puzzle at a time. Shortly after releasing it publicly, I wrote a set of hints and puzzle walkthroughs so 
                that one tricky or flawed puzzle wouldn't ruin the entire experience. 
            </p>

            <br /> <img src={ev5} width='100%' alt="illustration of a sink. puzzle notes: puzzle location, the Bathroom. clue location, N/A. leads to bitly link. how to solve: 
            Fide a note near a locked [ITEM] indicating (white) bit.ly/ off hot cold off. Based on the sink code, this leads to 35 245 134 245, which is the bitly." /><br /><br />
        
            <p> When I ran this game with my club, teams raced to be the first to finish. I presided as gamemaster to gave out hints and 
                clarifications. I would call it a success - every team completed the puzzles in roughly an hour, and everyone indulged in the spirit of 
                friendly competition. Expert puzzlers, the lot of them! Here's a photo from the aftermath: 
            </p>

            <br /> <img src={ev6} width='100%' alt="screenshot of a Zoom call with 28 members. near the top left, i'm squiting to look for people. everyone else has hidden
            themselves in their rooms, most with their heads peeking out just a bit." /><br /><br />
        
            <p> I drew a lot of home goods and wrote a lot of sonnets for this project, and I learned a lot about puzzle design and interaction along
                the way. If you want to play it, you can <a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/899042758064025062/escape-room-the-mystery-of-everson-manor">get the Figma file here</a> to get started!
            </p>
        
        </thing>
        
    )

}