import '../App.css';
import pu1 from '../images/pu1.png';
import pu2 from '../images/pu2.png';
import pu3 from '../images/pu3.png';
import pu4 from '../images/pu4.JPG';
export default function PawsUp() {
    return (
        <thing>
            <h1>Paws Up Redesign</h1>
            <hr />

            <p>Design Lead | Mar 2022 - Apr 2022 | <a target="_blank" rel="noreferrer" href="https://calblueprint.org/">Cal Blueprint</a> </p>
            <p>Collaborators: Designers, developers, club members </p>
            <p>Skills: Design leadership, rapid prototyping, critique, visual design</p>
            <img src={pu1} width='100%' alt="banner of a FigJam board with an agenda, 'PAWS UP' in bubble letters, and a brainstorming activity." />
            <br /><br />
            
            <p>Paws Up is an internal tool that we use to give shoutouts, kudos, and appreciation. Throughout the week, members can send in notes to people 
                for any reason. At general meetings, these anonymous messages are read aloud to everyone. It's sweet, and it's an important part of our club culture. </p>
            <p>Despite this, its visuals hadn't changed since its original proof of concept. During my semester as VP of Design at Blueprint,
                I led a long-awaited sprint to redesign Paws Up. The sprint was open to our 5 designers, as well as any member of the club who wanted to learn.</p>
            <br />
            <img src={pu3} width='100%' alt="a warmup activity about collectively planting a garden of our strenghts." />
            <br /><br />

            <p>To maximize efficiency in everyone's busy schedules, the sprint was split into 2 synchronous and 1 async session. Session one began with an affirmative
                 warmup to bolster confidence and context-setting to frame and understand the problem. We then spent time individually brainstorming and shared our ideas. </p>
            <br />
            <img src={pu4} width='100%' alt="screenshots of design concepts with dot votes near them" />
            <br /><br />
                 
            <p>Based on which ideas generated the most heat, I assigned directions to each designer, who would spend ~1.5 hours rapid prototyping with the goal of each person 
                exploring one interesting idea deeply. </p>
            <p>Finally, we came together for critique. By the end of the second session, we successfully converged on a unified set of designs and features that drew primarily
                from Nerissa Hsieh's concepts. Over the next month, I worked on the last mile of the designs to get them ready for development.</p>
            <br />    
            <img src={pu2} width='100%' alt="snippet of the final designs for Paws Up" />
            <br /><br />

            <p>Paws Up 2.0 is still in development, but in the meantime the club has continued putting it to good use!</p>

        </thing>
        
    )

}