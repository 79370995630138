import '../App.css';
import fg from '../images/fg.png';
export default function FigmaGrowth() {
    return (
        <thing>
            <h1>Growth @ Figma</h1>
            <hr />

            <p>Product Design Intern | Aug 2021 - Dec 2021 | <a target="_blank" rel="noreferrer" href="https://figma.com/">Figma</a> </p>
            <p>Collaborators: Design mentors, engineers, UX researchers, UX writers, project managers, data scientists </p>
            <p>Skills: Interaction design, UX writing, user flows, design systems, illustration</p>
            <p> View the case study presentation <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1ihh0u_3iNpDuI4tS10DR-2T2Y_ZnhH3d/view?usp=sharing">here</a>.</p>
            <br />
            <img src={fg} width='100%' alt="banner of several Figma design screens layered over a yellow background." />
            <br />
            <br />
            
            <p>I interned at Figma's Growth team, where my projects focused on product optimizations to increase rates of active users and editors.
                I also contributed to a Community team design sprint, created a widget with new friends, and spotted lots of bugs. </p>
            <p>Throughout my internship, I got to work closely with a wide array of collaborators, particularly with the wonderful UX writers and researchers.</p>
            <hr />

            

            <p>Beyond my core projects, I put a lot of heart into fostering team and company culture. I helped plan and run a Growth design sprint, 
                made top-secret assets for an internal company-wide make-a-thon, and illustrated virtual cards to bring good cheer to a virtual design team 
                holiday event. I'm grateful to everyone who made me feel valued during my first internship.</p>


            <br />

        </thing>
        
    )

}