import '../../App.css';
export default function NameField() {
    return (
        <thing>
            <h1>NAME is a Required Field: Politics of Deadnaming in Administrative Systems</h1>
            <hr />
            <p>Dec 2023 | Graduate Seminar Paper | <a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1bLQv1oV_wnAowUTOi2-x0KWyb4hV8wjaDnJ6ozEGkzw/edit?usp=sharing">Read here</a> </p>
            <p></p>
            <p>A paper interrogating deadnaming in administrative systems, drawing from both current literature
                and my own experiences at UC Berkeley. Written for Film 240: Politics of Code.
            </p>
            <hr />
            <p>"Deadnaming is a strategy for administrative bodies to control trans people, carried out by technologies which demand 
                legible, singular identification. Interfaces that introduce ‘lived name’ fields are insufficient at best and violent 
                at worst, as demonstrated by a close read of the UC Gender Recognition and Lived Name Policy; under the guise of a 
                solution, they produce arbitrary binaries and force trans people to fulfill the impossible task of making themselves 
                legible to an opaque system. By embracing the mutability of names, trans people can reveal points of breakdown and 
                envision alternatives focused on evasion and multiplicity rather than surveillance and accuracy."
            </p>
        </thing>
        
    )

}