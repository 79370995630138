import '../../App.css';
import lv from './images/lovers.png';
export default function Lovers() {
    return (
        <thing>
            <h1>Lovers Wiki</h1>
            <hr />
            <p>May 2024 | Interactive Fiction | <a target="_blank" rel="noreferrer" href="https://www.lovers.wiki">Explore here</a> </p>
            <p></p>
            <br />
            <img src={lv} width='100%' alt="ASCII art of flowers with the following text: CONTRIBUTORS. People who have loved us (and who they love.)" />
            <br />
            <br />
            <p>In the face of an inevitable apocalypse, someone creates a wiki about people that he loves. 
                Made for Media Studies 190, Digital Storytelling: Crisis, Catastrophe, and Speculative Futures. 
            </p>
            <hr />
            <p>Who do you love?</p>
        </thing>
        
    )

}