import '../App.css';
import m from '../images/merl.png';
export default function MERL() {
    return (
        <thing>
            <h1>Media Education Research Lab</h1>
            <hr />

            <p>Advised by&nbsp;
                <a target="_blank" rel="noreferrer" href="https://jingyi.me/">Dr. Jingyi Li</a> 
                &nbsp;and <a target="_blank" rel="noreferrer" href="https://tdps.berkeley.edu/people/abigail-de-kosnik">Dr. Abigail De Kosnik</a></p>
            <p>Undergraduate Research Assistant | Sep 2023 - Present  </p>
            <br />
            <img src={m} width='100%' alt="website mockup that shows WandaVision with a diversity score of 3 and a quote reading 
            'WandaVision isn't particularly offensive when it comes to diversity on screen, but it's not good either.'"/>
            <br />
            <br />
            
            <p>At MERL, I'm currently designing and developing an educational site to visualize diversity in film and TV.</p>
            <p>How can we make more informed and critical decisions about the media we choose to consume and create? What might 
                empowering, intersectional, or otherwise ‘good’ representation look like?
            </p>
            <hr />
            <p>Or: find out why I'm upset that they keep casting Chris Pratt to voice animated chracters.</p>

        </thing>
        
    )

}