import '../../App.css';
import p2 from './images/stuff/p2.jpg';
import p3 from './images/stuff/p3.jpg';
import p4 from './images/stuff/p4.jpg';
import p5 from './images/stuff/p5.jpg';
import p6 from './images/stuff/p6.jpg';
import p7 from './images/stuff/p7.jpg';
import p8 from './images/stuff/p8.jpg';
import p9 from './images/stuff/p9.jpg';
import p10 from './images/stuff/p10.jpg';
import p11 from './images/stuff/p11.jpg';
export default function STUFF() {
    return (
        <thing>
            <h1>STUFF</h1>
            <hr />
            <p>Apr 2022 | Modeled by Jenny</p>
            <p>A tribute to stuff.</p>
            {/* TODO: WRITE ALT TEXT */}
            <img src={p2} width='100%' alt="" />
            <img src={p3} width='100%' alt="" />
            <img src={p4} width='100%' alt="" />
            <img src={p5} width='100%' alt="" />
            <img src={p6} width='100%' alt="" />
            <img src={p7} width='100%' alt="" />
            <img src={p8} width='100%' alt="" />
            <img src={p9} width='100%' alt="" />
            <img src={p10} width='100%' alt="" />
            <img src={p11} width='100%' alt="" />
            <br />
            <p>In this case, more is more is more is more. </p>
            <p>I asked my friend with the best accessories to model, filled a giant tote bag with toys and cards and stickers and candy,
                dumped it all out on their floor, and shot every idea we came up with.</p>
            <p>We had a lot of fun and I hope you enjoy this zine! Shot for Photo Decal.</p>
        </thing>
        
    )

}