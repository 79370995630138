import '../../App.css';
import ac from './images/animalcrossing.png';
export default function AnimalCrossing() {
    return (
        <thing>
            <h1>Animal Crossing Character Maker</h1>
            <hr />

            <p>May 2020 | <a target="_blank" rel="noreferrer" href="https://www.figma.com/community/file/834210307944210537/animal-crossing-character-maker">Play on Figma</a> </p>
            <br />
            <img src={ac} width='100%' alt="illustration of 9 different animal crossing-style avatars" />
            <br />
            <br />
            
            <p>Create your own Animal Crossing-style character using Figma components! Now you, too, can experience Animal Crossing 
                New Horizons without a Switch. </p>
            <hr />
            <p>I created this to learn how to illustrate in Figma and develop a better understanding of components. Since then, variants 
                and interactive components came out, but I haven't updated this file because I got a Switch :D</p>
        </thing>
        
    )

}