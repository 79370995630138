import '../../App.css';
import p1 from './images/artexhibit/1.jpg';
import p2 from './images/artexhibit/2.jpg';
import p3a from './images/artexhibit/3a.jpg';
import p3b from './images/artexhibit/3b.jpg';
import p4 from './images/artexhibit/4.jpg';
import p5 from './images/artexhibit/5.jpg';
import p6 from './images/artexhibit/6.jpg';
import p7a from './images/artexhibit/7a.jpg';
import p7b from './images/artexhibit/7b.jpg';
import p8 from './images/artexhibit/8.jpg';
import p9 from './images/artexhibit/9.jpg';
import p10 from './images/artexhibit/10.jpg';
import p11 from './images/artexhibit/11.jpg';
import p12 from './images/artexhibit/12.jpg';
import p13 from './images/artexhibit/13.jpg';
export default function ArtExhibit() {
    return (
        <thing>
            <h1>Art Exhibit</h1>
            <hr />
            <p>Mar 2022 | Modeled by Maile and Ceci </p>
            <p>My friend showed me <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=p11XD7Zhw4U">this version</a> of 
            Art Exhibit by Young the Giant and it became my top song of 2022. Shot for Photo Decal.</p>
            
            <br />
            <p>...</p>
            {/* TODO: WRITE ALT TEXT */}
            <br />
            <p>i saw a picture of you today / at an art exhibit / on memory lane</p> <br />
            <img src={p1} width='100%' alt="" />

            <br /><br /> <p>you wore those pearls we found on champs-elysees / framed like the golden masters</p> <br />
            <img src={p2} width='100%' alt="" />

            <br /><br /> <p>forgotten all these years / pouring like rain / a true thought appears / oh, the genius of pain</p> <br />
            <img src={p3a} width='100%' alt="" />
            <img src={p3b} width='100%' alt="" />

            <br /><br /> <p>without a name / pouring like rain </p> <br />
            <img src={p4} width='100%' alt="" />

            <br /><br /> <p>cause i’m on my back / on my back again / words we had to describe the same feeling / now without a meaning</p> <br />
            <img src={p5} width='100%' alt="" />

            <br /><br /> <p>cause i’m on my back / on my back again / looking at / a hole in the ceiling</p> <br />
            <img src={p6} width='100%' alt="" />

            <br /><br /> <p>i watched a movie of you today / silver screen adapted / from my thoughts on broadway </p> <br />
            <img src={p7a} width='100%' alt="" />
            <img src={p7b} width='100%' alt="" />

            <br /><br /> <p>you saved the world / we lived in such harmony / blockbuster sales in twelve countries</p> <br />
            <img src={p8} width='100%' alt="" />

            <br /><br /> <p>remembered all those years / falling like rain / a true thought appears / oh, the genius of pain</p> <br />
            <img src={p9} width='100%' alt="" />

            <br /><br /> <p>cause i’m on my back / on my back again / words we had / to describe the same feeling</p> <br />
            <img src={p10} width='100%' alt="" />

            <br /><br /> <p>now without a meaning</p> <br />
            <img src={p11} width='100%' alt="" />

            <br /><br /> <p>cause i’m on my back / on my back again / looking at / a hole in the ceiling</p> <br />
            <img src={p12} width='100%' alt="" />
            <img src={p13} width='100%' alt="" />

        </thing>
        
    )

}