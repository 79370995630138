import '../../App.css';
import lw from './images/lw.jpg';
export default function LateWedding() {
    return (
        <thing>
            <h1>The Late Wedding</h1>
            <hr />
            <p>Nov 2022 | Assistant Costume Designer to Miyuki Bierlien </p>
            <br />
            <img src={lw} width='100%' alt="the cast and crew of The Late Wedding, mostly wearing black, standing onstage together." />
            <br />
            <br />
            
            <p>I spent most of Fall 2022 working on costumes for the UC Berkeley TDPS department's production of <i>The Late Wedding</i> by 
                Christopher Chen! I helped wherever I was needed, mostly by taking notes during fittings and getting lost in the 
                costume cages. (That's me in the red jacket!)</p>
            <hr />
            <p>While I don't have many photos to show for it, I am eternally grateful to the cast and crew I met during this experience.
                They made me really love theater - enough to declare a minor in it - and I couldn't be happier.
            </p>
        </thing>
        
    )

}