import '../App.css';
export default function Postscript() {
    return (
        <div>
			<details><summary>07 postscript</summary>
				<p>This website is under construction. It will probably always be under construction! I try to update it semi-regularly, so 
				let me know if there's any fun ideas you'd like to see me add :~)</p> 
				<p>Made by Ace S Chen with a lot of HTML tags.</p>
			</details>
        </div>        
    )
}